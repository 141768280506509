



















































// CORE
import { Component, Mixins } from 'vue-property-decorator'

// COMPONENTS
import DefaultLayout from '@/components/layouts/DefaultLayout.vue'
import RequestFormPartWrapper from '@/components/RequestFormPartWrapper.vue'
import OrderCard from '@/components/cards/OrderCard.vue'
import CarOrderView from '@/components/orderViews/CarOrderView.vue'
import CharacterCard from '@/components/cards/CharacterCard.vue'

// STORE
import FleetOrderModule from '@/store/modules/fleet/order'
import DriverOrderModule from '@/store/modules/driver/order'

// MIXINS
import SystemMixin from '@/mixins/SystemMixin'
import NotifyMixin from '@/mixins/NotifyMixin'

// HELPERS
import { parseDate } from '@/utils/functions'
import { RequestTypeEnum } from '@/store/types'

@Component({
  components: {
    DefaultLayout,
    CharacterCard,
    CarOrderView,
    OrderCard,
    RequestFormPartWrapper,
  },
})
export default class CarBuybackOrderView extends Mixins(SystemMixin, NotifyMixin) {
  private get orderRequestParams () : { id: number, type: RequestTypeEnum } {
    const id = +this.$route.params.orderId
    const type = RequestTypeEnum.CAR_BUYBACK

    return { id, type }
  }

  private get order (): any {
    if (this.role === 'fleet' || this.role === 'mechanic') {
      return FleetOrderModule.order
    } else {
      return DriverOrderModule.order
    }
  }

  private created () {
    if (this.role === 'fleet' || this.role === 'mechanic') {
      FleetOrderModule.getOrder(this.orderRequestParams)
        .catch(() => {
          this.notifyError('Заявка не найдена')
          this.$router.push({ name: 'orders' })
        })
    } else {
      DriverOrderModule.getOrder(this.orderRequestParams)
        .catch(() => {
          this.notifyError('Заявка не найдена')
          this.$router.push({ name: 'orders' })
        })
    }
  }

  private parseDate = parseDate
}
